import React, { useCallback, useState } from "react";
import "./App.css";
import useIntervalAsync from "./useIntervalAsync";
import dayjs from "dayjs";
import axios from "axios";

function App() {
  const searchParams = new URLSearchParams(document.location.search);
  // console.log(searchParams.get("location"));
  const location = searchParams.get("location");
  const [origin, setOrigin] = useState([]);
  const api_url = process.env.REACT_APP_API_URL;

  const updateState = useCallback(async () => {
    console.log("Set Interval");

    const baseurl = `${api_url}/clover/order_status_id/${location}`;

    const myHeaders = new Headers();
    myHeaders.append("accept", "application/json");
    myHeaders.append(
      "authorization",
      "Bearer 247ac05a-79a3-8532-ebb2-1eeaef16638b"
    );

    const res = await axios.get(baseurl, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer 247ac05a-79a3-8532-ebb2-1eeaef16638b",
      },
      data: "",
    });

    const data = res.data.data;
    console.log(data.elements);
    setOrigin(data.elements ?? "");
  }, [location]);
  useIntervalAsync(updateState, 3000);

  const setTime = (time: number) => {
    return dayjs(time).format("hh:mm:ss A");
  };

  type Modifications = {
    elements?: ElementsEntity2[] | null;
  };
  type ElementsEntity2 = {
    id: string;
    name: string;
    amount: number;
  };

  type LineItemsFields = {
    name: string;
    modifications?: Modifications | null;
  };

  type LineItems = {
    elements?: LineItemsFields[] | null;
  };

  type Items = {
    paymentState: string;
    createdTime: number;
    lineItems?: LineItems | null;
  };

  const noWords = ["No Perejil", "No Queso"];

  const checkNoWords = (word: string) => {
    return noWords.includes(word);
  };

  return (
    <div className="wf-section">
      <div className="header"></div>
      <div className="columns_div">
        {origin.map((value: Items, index) => {
          return (
            <div key={index}>
              {value.lineItems && (
                <div className="order_div w-container">
                  <div className="div-block">
                    <div className="time_text">
                      Time: {setTime(value.createdTime)}
                    </div>

                    {value.lineItems?.elements?.map(
                      (value2: any, index: number) => {
                        return (
                          <div key={index}>
                            <div className="itemtitle">{value2.name}</div>

                            {value2.modifications?.elements.map(
                              (value3: any, index: number) => {
                                return (
                                  <div
                                    key={index}
                                    className={`subitem_text ${
                                      checkNoWords(value3.name)
                                        ? "red"
                                        : "hidden"
                                    }`}
                                  >
                                    {value3.name}
                                  </div>
                                );
                              }
                            )}
                          </div>
                        );
                      }
                    )}
                  </div>
                </div>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default App;
